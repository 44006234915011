<script>
import Layout from '../../layouts/main';
import { api } from '@/state/services';
import { VMoney } from 'v-money';
import { required } from 'vuelidate/lib/validators';

export default {
  locales: {
    pt: {
      'Send receipt': 'Enviar comprovante',
      'Bank is required': 'Banco é obrigatório',
      'Amount is required': 'Valor é obrigatório',
      'Date is required': 'Data é obrigatório',
      'File is required': 'Comprovante é obrigatório',
      'Deposit amount': 'Valor do depósito',
      'Choose a file or drop it here...': 'Escolha um arquivo arraste aqui...',
      'Drop file here...': 'Solte o arquivo aqui...',
      'Please enter the correct date': 'Por favor insira a data correta.',

      'Bank': 'Banco',
      'Date': 'Data',
      'Send': 'Enviar'
    },
    es: {
      'Send receipt': 'Enviar recibo',
      'Bank is required': 'Se requiere banco',
      'Amount is required': 'Se requiere valor',
      'Date is required': 'Se requiere fecha',
      'File is required': 'Se requiere prueba',
      'Deposit amount': 'Cantidad del depósito',
      'Choose a file or drop it here...': 'Elija un archivo arrastre aquí...',
      'Drop file here...': 'Suelta el archivo aquí...',
      'Please enter the correct date': 'Por favor, introduzca la fecha correcta',

      'Bank': 'Banco',
      'Date': 'Fecha',
      'Send': 'Enviar'
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      order: {
        id: this.$route.params.id,
      },

      receipt: false,
      loading: false,
      success: false,

      banks: {
        options: null
      },
      banksList: null,

      value: '',
      date: '',
      file: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },

      alert: {
        type: '',
        message: ''
      },
    }
  },
  directives: { money: VMoney },
  validations: {
    bank: { required },
    date: { required },
    value: { required },
    file: { required },
  },
  methods: {
    fileSelected(event) {
      this.file = event.target.files[0].name
    },
    receiptSubmit() {
      const fd = new FormData();
      fd.append('image', this.file, this.file.name),

      this.loading = true
      this.$v.$touch();

      const date = new Date(this.date);
      const newdate = new Date(date.toString(date));

      const data = [
        this.banksList,
        newdate.getTime() / 1000,
        this.value.replace(/[^0-9,]*/g, '').replace(',', '.'),
      ];

      if (this.value) {
        api
          .post(`wallet/order/deposit/send/${data[0]}/${data[1]}/${data[2]}/${this.order.id}`, fd)
          .then(response => {
            if (response.data.status=='success') {
              this.success = true
              this.value = 'R$ 0,00'
              this.$v.$reset();

              this.$router.push('/wallet/order/deposit/receipt/' + this.order.id);
            } else {
              this.alert.type = 'alert-danger'
              this.alert.message = response.data.message
              this.value = 'R$ 0,00';

              this.loading = false
            }
          });
      }

    },
    getBanks() {
      api
        .get('banks')
        .then(response => {
          if (response.data.status==='success') {
            this.banks.options = response.data.list;
          }
        });
    }
  },
  mounted() {
    this.getBanks();
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Send receipt') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-7">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="!receipt">
            </div>
            <div v-if="success" class="text-center-sm">
              {{ t('Your withdrawal has been successfully requested.') }}
            </div>

            <b-form v-else class="p-3">
              <div v-if="alert.message" :class="'alert ' + alert.type">{{ alert.message }}</div>
              <b-form-group id="bank" :label="t('Bank')" label-for="bank">
                <select class="form-control" name="bank" id="bank" v-model="banksList">
                  <option v-for="ban in banks.options" :key="ban.number" :value="ban.number">
                    {{ ban.number }} - {{ ban.name }}
                  </option>
                </select>
                <div v-if="$v.bank.$error" class="invalid-feedback">
                  <span v-if="!$v.bank.required">{{ t('Amount is required.') }}</span>
                </div>
              </b-form-group>
              <b-form-group id="date" :label="t('Date')" label-for="date">
                <b-form-input id="date" v-model="date" type="text" v-mask="'##/##/#### ##:##:##'"></b-form-input>
                <small>{{ t('Please enter the correct date') }}</small>
                <div v-if="$v.date.$error" class="invalid-feedback">
                  <span v-if="!$v.date.required">{{ t('Amount is required.') }}</span>
                </div>
              </b-form-group>
              <b-form-group id="value" :label="t('Deposit amount')" label-for="value">
                <b-form-input id="value" v-model="value" type="text" inputmode="decimal" v-money="money" :class="{ 'is-invalid': $v.value.$error }"></b-form-input>
                <div v-if="$v.value.$error" class="invalid-feedback">
                  <span v-if="!$v.value.required">{{ t('Amount is required.') }}</span>
                </div>
              </b-form-group>
              <b-form-file
                @change="fileSelected"
                v-model="file"
                :state="Boolean(file)"
                :placeholder="t('Choose a file or drop it here...')"
                :drop-placeholder="t('Drop file here...')"
              ></b-form-file>
              <div v-if="$v.file.$error" class="invalid-feedback">
                <span v-if="!$v.file.required">{{ t('Amount is required.') }}</span>
              </div>
              <div class="mt-4">
                <b-button @click="receiptSubmit()" :disabled="loading == true || this.value==='R$ 0,00'" type="submit" variant="danger">
                  {{ t('Send') }}
                  <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>